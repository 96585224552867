html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
}
