body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

h1 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.input {
  border: 1px solid #333;
  border-radius: 0.3em;
  padding: 0.25rem;
  width: 7em;
}

select {
  margin-left: 0.5rem;
}

.equals {
  font-weight: bold;
  font-size: 2rem;
}
